import { createStore } from "vuex";

export default createStore({
  state: {
    data: {},
    index: 0,
    tutorial: false,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.data = data;
    },
    SET_INDEX: (state, index) => {
      state.index = index;
    },
    SET_TUTORIAL: (state, value) => {
      state.tutorial = value;
    }
  },
  getters: {
    data(state) {
      return state.data;
    },
    index(state) {
      return state.index;
    },
    tutorial(state) {
      return state.tutorial
    }
  },
  actions: {
    setData(context, data) {
      context.dispatch("SET_DATA", data);
    },
    setIndex(context, index) {
      context.dispatch("SET_INDEX", index);
    },
    setTutorial(context, value){
      context.dispatch("SET_TUTORIAL", value);
    }
  },
  modules: {},
});
