import { createApp } from 'vue'
import App from './App.vue'
import './main.css'
import './assets/esri/main.css';
import router from './router'
import store from './store'
import titleMixin from './mixins/title'

const app = createApp(App);
app.mixin(titleMixin);
app.use(store).use(router).mount('#app')

