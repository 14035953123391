<template>
  <main-layout>
    <div id="map" class="bg-gray-300 w-full h-full">
      <div
        v-if="swipeTitleTrail"
        id="right-layer-name"
        class="
          absolute
          top-32
          right-32
          p-3
          max-w-sm
          mx-auto
          bg-black bg-opacity-50
          rounded-xl
          shadow-md
          flex
          items-center
        "
      >
        <p class="text-white pb-0">{{ swipeTitleTrail }}</p>
      </div>
      <div
        v-if="swipeTitleLead"
        id="left-layer-name"
        class="
          absolute
          top-32
          right-1/2
          p-3
          max-w-sm
          mx-auto
          bg-black bg-opacity-50
          rounded-xl
          shadow-md
          flex
          items-center
        "
      >
        <p class="text-white pb-0">{{ swipeTitleLead }}</p>
      </div>
    </div>
  </main-layout>
  <div
    class="p-5 border w-96 shadow-lg rounded-md bg-white hidden z-200 absolute"
    id="tooltip-modal"
  >
    <div class="mt-3 text-center">
      <div class="mt-2 px-7 py-3">
        <p class="text-sm text-gray-500">{{ modalMessage }}</p>
      </div>
      <div class="items-center px-4 py-3">
        <button
          id="ok-btn"
          class="
            px-4
            py-2
            bg-green-500
            text-white text-base
            font-medium
            rounded-md
            w-full
            shadow-sm
            hover:bg-green-600
            focus:outline-none focus:ring-2 focus:ring-green-300
          "
          @click="modalStep++"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted, ref } from "vue";
import { useStore } from "vuex";
import MainLayout from "../layouts/Main";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import Portal from "@arcgis/core/portal/Portal";
import Config from "@arcgis/core/config";
import LayerList from "@arcgis/core/widgets/LayerList";
import Swipe from "@arcgis/core/widgets/Swipe";
import * as watchUtils from "@arcgis/core/core/watchUtils";

export default {
  title: "ESA - Map",
  components: {
    MainLayout,
  },
  setup() {
    const store = useStore();
    const data = computed(() => store.state.data);
    const index = computed(() => store.state.index);
    const tutorial = computed(() => store.state.tutorial);
    const webmaps = [];
    let allItems = null;
    let layerList1 = null;
    let mapView = null;
    let aSwipe = null;
    const swipeTitleLead = ref(null);
    const swipeTitleTrail = ref(null);
    const modalMessage = ref("Use swiper to compare different layers");
    const modalStep = ref(false);
    const watchers = [];
    let modalElement = null;
    let modalBD = null;
    const tripElement = ref(null);
    let leftTitle = null;
    let rightTitle = null;

    onMounted(() => {
      leftTitle = document.getElementById("left-layer-name");
      rightTitle = document.getElementById("right-layer-name");
      modalElement = document.getElementById("tooltip-modal");
      modalBD = document.getElementById("modal-backdrop");
      Config.portalUrl = "https://mygis.engeo.com/portal/sharing/";

      let portal = new Portal({
        url: Config.portalUrl,
      });

      const aWebMap = new WebMap({
        portalItem: { id: data.value[index.value].mapId, portal },
      });
      webmaps[0] = aWebMap;
      mapView = new MapView({
        container: "map",
        map: aWebMap,
      });
      mapView.when(() => {
        let promises = [];
        allItems = mapView.map.allLayers.filter((layer) => layer.visible);
        allItems.forEach((aItem) => {
          promises.push(aItem);
        });
        removeWatchers();
        Promise.allSettled(promises).then(() => {
          allItems.forEach((aItem) => {
            watchLayerVisibility(aItem);
          });
        });
        setSwipe(index.value);
      });
    });
    watch(modalStep, (updated) => {
      if (updated == false) return;
      leftTitle = document.getElementById("left-layer-name");
      rightTitle = document.getElementById("right-layer-name");
      if (tripElement.value) toggleTooltip(true, false);
      if (updated > -1) {
        const swiperTour = {
          0: {
            text: "Use swiper to compare different layers",
            element: aSwipe._container,
            esri: true,
            position: "right",
          },
          1: {
            text: "Hide or show different layers for comparison",
            element: document
              .getElementsByClassName("esri-ui-bottom-right")
              .item(0),
            esri: true,
            position: "left",
          },
          2: {
            text: "Title of the leading layer",
            element: leftTitle,
            position: "bottom",
          },
          3: {
            text: "Title of the trailing layer",
            element: rightTitle,
            position: "bottom",
          },
        };

        if (updated > -1 && swiperTour[updated] && !tutorial.value) {
          modalMessage.value = swiperTour[updated].text;
          addTooltip(
            swiperTour[updated].element,
            swiperTour[updated].position,
            swiperTour[updated].esri
          );
        }
      }
    });
    watch(index, (updated) => {
      leftTitle = document.getElementById("left-layer-name");
      rightTitle = document.getElementById("right-layer-name");
      toggleTooltip(true, false);
      if (leftTitle && !leftTitle.classList.contains("hidden"))
        leftTitle.classList.add("hidden");
      if (rightTitle && !rightTitle.classList.contains("hidden"))
        rightTitle.classList.add("hidden");
      mapView.ui.remove(layerList1);
      mapView.ui.remove(aSwipe);
      layerList1 = null;
      aSwipe = null;
      if ((!updated && updated != 0) || !mapView) return;
      if (!webmaps[updated]) {
        const newWebMap = new WebMap({
          portalItem: { id: data.value[updated].mapId },
        });
        webmaps[updated] = newWebMap;
      }
      mapView.ui.empty("bottom-right");
      mapView.ui.components = [];
      mapView.map = webmaps[updated];
      allItems = mapView.map.allLayers.filter((layer) => layer.visible);
      mapView.map.load().then(() => {
        modalStep.value = 0;
        setSwipe(updated);
      });
    });

    const setSwipe = (updated) => {
      if (!layerList1 && data.value[updated].swipe) {
        layerList1 = new LayerList({
          view: mapView,
        });
        mapView.ui.add(layerList1, { position: "bottom-right" });
      }
      if (!aSwipe && data.value[updated].swipe) {
        let allLayersArr = allItems;
        allLayersArr.pop();
        aSwipe = new Swipe({
          view: mapView,
          leadingLayers: [...allLayersArr],
          trailingLayers: [...allItems],
          id: `swipe-default`,
        });
        aSwipe.when(() => {
          if (modalStep.value == 0 && !!layerList1) {
            modalMessage.value = "Use swiper to compare different layers";
            addTooltip(aSwipe._container, "right", true);
          }
        });
        mapView.ui.add(aSwipe);
        resetSwipe();
      }
    };

    const toggleEsriIndex = (set = false) => {
      const elements = document.getElementsByClassName("esri-ui");
      for (let aElement of elements) {
        if (!set) {
          aElement.classList.remove("z-300");
        } else if (set && !aElement.classList.contains("z-300")) {
          aElement.classList.add("z-300");
        }
      }
    };

    const addTooltip = (tooltipElement, position = "left", esri = false) => {
      tripElement.value = tooltipElement;
      toggleTooltip(false, esri);
      const bounds = tooltipElement.getBoundingClientRect();
      const modalBounds = modalElement.getBoundingClientRect();
      let top =
        (bounds.bottom - bounds.top) / 2 +
        bounds.top -
        modalElement.clientHeight / 2;
      let left = bounds.right + 20;
      if (position == "left") {
        left = bounds.left - bounds.width - modalBounds.width / 4;
      }
      if (position == "bottom") {
        left = bounds.left - bounds.width / 2;
        top = bounds.height + bounds.top + modalBounds.height / 4;
      }
      modalElement.style = `top: ${top}px; left: ${left}px;`;
    };

    const toggleTooltip = (remove = false, esri = false) => {
      if (!tripElement.value) {
        modalBD.classList.add("hidden");
        modalElement.classList.add("hidden");
        return;
      }
      toggleEsriIndex(esri);
      if (remove) {
        if (!modalBD.classList.contains("hidden"))
          modalBD.classList.add("hidden");
        if (!modalElement.classList.contains("hidden"))
          modalElement.classList.add("hidden");
        if (tripElement.value.classList.contains("z-300"))
          tripElement.value.classList.remove("z-300");
        if (tripElement.value.classList.contains("ring-2"))
          tripElement.value.classList.remove("ring-2");
        if (tripElement.value.classList.contains("ring-green-300"))
          tripElement.value.classList.remove("ring-green-300");
      }
      if (!remove) {
        if (modalBD.classList.contains("hidden"))
          modalBD.classList.remove("hidden");
        if (modalElement.classList.contains("hidden"))
          modalElement.classList.remove("hidden");
        if (!tripElement.value.classList.contains("z-300") && !esri) {
          tripElement.value.classList.add("z-300");
        }
        if (!tripElement.value.classList.contains("ring-2"))
          tripElement.value.classList.add("ring-2");
        if (!tripElement.value.classList.contains("ring-green-300"))
          tripElement.value.classList.add("ring-green-300");
      }
    };
    const watchLayerVisibility = (aItem) => {
      watchers.push(watchUtils.watch(aItem, "visible", resetSwipe));
    };
    const removeWatchers = () => {
      for (let x = watchers.length - 1; x >= 0; x--) {
        watchers[x].remove();
        watchers.pop();
      }
    };

    const resetSwipe = () => {
      allItems = mapView.map.allLayers
        .filter((layer) => layer.type == "tile")
        .toArray();
      let promises = [];
      removeWatchers();
      allItems.forEach((aItem) => {
        watchLayerVisibility(aItem);
        promises.push(aItem);
      });
      Promise.allSettled(promises).then(() => {
        const allLayersArr = allItems.filter((aItem) => aItem.visible);
        aSwipe.leadingLayers.removeAll();
        aSwipe.leadingLayers.addMany([...allLayersArr]);
        allLayersArr.pop();
        swipeTitleLead.value =
          aSwipe.leadingLayers.items[
            aSwipe.leadingLayers.items.length - 1
          ].title;
        aSwipe.trailingLayers.removeAll();
        aSwipe.trailingLayers.addMany([...allLayersArr]);
        swipeTitleTrail.value =
          aSwipe.trailingLayers.items[
            aSwipe.trailingLayers.items.length - 1
          ].title;
        if (leftTitle && leftTitle.classList.contains("hidden"))
          leftTitle.classList.remove("hidden");
        if (rightTitle && rightTitle.classList.contains("hidden"))
          rightTitle.classList.remove("hidden");
      });
    };

    return {
      data,
      webmaps,
      mapView,
      swipeTitleLead,
      swipeTitleTrail,
      modalMessage,
      modalStep,
    };
  },
};
</script>

<style lang="sass">
@import "../assets/esri/main.css"
</style>
