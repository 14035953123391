<template>
  <router-view />
</template>

<script>
import { onMounted } from "vue";
import json from "@/assets/data.js";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("SET_DATA", json);
    })
  },
};
</script>
