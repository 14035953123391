<template>
  <div
    class="min-h-screen min-w-screen main-bg object-contain bg-center flex flex-col justify-between"
  >
    <div class="font-sans text-4xl font-extrabold mx-auto mt-12 p-6">
      <span class="p-4 text-black bg-white bg-opacity-70 rounded-lg">
        Balboa Reservoir GIS ESA
      </span>
    </div>
    <div class="mx-auto mb-24">
      <button
        @click="signIn"
        class="whitespace-nowrap inline-flex rounded-md bg-white py-2 px-3 text-2xl font-semibold text-blue-500 hover:bg-opacity-90"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRouter } from 'vue-router'
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import Config from "@arcgis/core/config";

export default {
  name: "Home",
  title: "ESA - Login",
  setup() {
    const router = useRouter();
    const signIn = () => {
      Config.portalUrl = "https://mygis.engeo.com/portal/sharing/";
      Config.request.timeout = 60000;
      login(Config);
    };
    const login = (config) => {
      let authKey = window.localStorage.getItem("authKey");
      if (authKey) {
        IdentityManager.initialize(JSON.parse(authKey));
        start(config);
      } else {
        IdentityManager.getCredential(config.portalUrl).then(() => {
          let authKeyJson = JSON.stringify(IdentityManager.toJSON());
          window.localStorage.setItem("authKey", authKeyJson);
          start(config);
        });
      }
    };
    const start = (config) => {
      config.request.interceptors.push({
        urls: /FeatureServer\/\d+$/,
        after: function(response) {
          response.data.supportedQueryFormats = "JSON";
        },
      });
      IdentityManager.checkSignInStatus(config.portalUrl)
        .then(() => {
          initiateMap();
        })
        .catch(() => {
          window.localStorage.removeItem("authKey");
          login(config);
        });
    };

    const initiateMap = () => {
      router.push('/map');
    };

    return { signIn, router };
  },
};
</script>

<style lang="sass" scoped>
.main-bg
  background-image: url('../assets/images/Demopage.png')
</style>
