<template>
  <div class="min-h-screen" v-if="opened">
    <heading></heading>
    <div class="relative md:flex h-screen">
      <div
        class="
          overflow-y-auto overflow-x-hidden
          sidebar
          bg-blue-200
          text-blue-800
          w-4/12
          space-y-6
          mt-16
          absolute
          inset-y-0
          left-0
          transform
          -translate-x-full
          md:relative
          md:translate-x-0
          transition
          duration-200
          ease-in-out
        "
      >
        <nav>
          <div v-for="point in data" :key="point.mapId">
            <a
              @click="setOpened(point)"
              :class="`
                block
                py-2.5
                px-4
                transition
                duration-200
                ${opened.includes(point) ? 'bg-blue-100' : ''}
                hover:bg-blue-700
                hover:text-white`
              "
            >
              {{ point.title }}
            </a>
            <div
              v-if="opened.includes(point)"
              v-html="md.render(point.body)"
              class="list-disc pl-6 bg-gray-200 rounded"
            ></div>
          </div>
        </nav>
      </div>
      <!-- content -->
      <div class="bg-gray-400 w-8/12 mt-16" v-if="data">
        <slot></slot>
      </div>
    </div>
  </div>
  <div class="hidden fixed inset-0 bg-gray-800 bg-opacity-60 overflow-y-auto h-full w-full z-100" id="modal-backdrop"></div>
</template>

<script>
import Heading from "../components/Header";
import { useStore } from "vuex";
import { computed, reactive } from "vue";

export default {
  components: {
    Heading,
  },
  setup() {
    const MarkdownIt = require("markdown-it");
    const md = new MarkdownIt({
      breaks: true,
      html: true,
      linkify: true,
      typographer: true,
    });
    const opened = reactive([0]);
    const store = useStore();
    const data = computed(() => store.state.data);
    const index = computed(() => store.state.index);
    opened.push(data.value[index.value]);
    const setOpened = (value) => {
      if (!opened.includes(value)) {
        opened.length = 0;
        opened.push(value);
        const key = data.value.findIndex((item) => item == value);
        store.commit("SET_INDEX", key);
      } else {
        opened.splice(opened.indexOf(value), 1);
      }
    };
    return { data, index, opened, setOpened, md };
  },
};
</script>

<style lang="sass">
li
  list-style-type: disc
  padding-left: 2em

ul
  padding-top: 1em

p
  padding-bottom: 1em
</style>
